<template>
  <div class="headline">
    <span>{{ headLine }}</span>
  </div>
</template>

<script>
export default {
  props: ['headLine'],
}
</script>

<style scoped lang="scss">
.headline {
  position: absolute;
  top: 1.5%;
  height: 5vh;
  width: 100%;
  z-index: 6;
  text-align: center;

  span {
    position: relative;
    color: white;
    font-size: 13px;
    width: 100%;
  }
}
</style>
